/* typography, layout, form elements */
.act-branding h1,
.act-branding h2,
.act-branding h3,
.act-branding h4,
.act-branding h5,
.act-branding h6,
.toc span:hover {
  color: var(--main_accent_act);
}
.act-branding .dark-bg {
  background: var(--main_accent_act-gradient);
}
.act-branding .dark-bg p,
.act-branding .dark-bg a,
.act-branding .dark-bg h1,
.act-branding .dark-bg h2,
.act-branding .dark-bg h3,
.act-branding .dark-bg h4,
.act-branding .dark-bg h5,
.act-branding .dark-bg h6 {
  color: white;
}
.act-branding .button a:link,
.act-branding .button a:visited,
.act-branding .button a:active,
.act-branding .button a:focus {
  font-weight: 700;
  background-color: var(--main_accent_act);
  background-image: var(--main_accent_act-gradient);
  color: white;
  padding: 16px 38px;
  border-radius: 100px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.5s ease;
  border: 2px solid var(--main_accent_act);
}
.act-branding .button a:hover {
  background: white;
  color: var(--main_accent_act);
  transition: all 0.5s ease;
}
.act-branding .button_inverted a:link,
.act-branding .button_inverted a:visited {
  background-color: white;
  color: var(--main_accent);
  border: 2px solid var(--main_accent_act);
}

/* nav */
.act-branding nav {
  background: var(--main_accent_act-gradient);
}
.act-branding nav img {
  max-height: 45px;
}
.act-branding #nav-toggle span,
.act-branding #nav-toggle span:before,
.act-branding #nav-toggle span:after {
  background: #fff;
}
.act-branding nav .language-switcher-act button,
.act-branding nav a {
  color: white;
}
.act-branding nav a:hover {
  color: white;
  opacity: 0.6;
  border-bottom-color: transparent;
}
.act-branding nav.toggled .language-switcher-act button,
.act-branding nav.toggled a {
  color: var(--main_accent_act);
}
.act-branding nav.toggled a:hover {
  color: var(--dark_accent-act);
  opacity: 1;
  border-bottom-color: transparent;
}

.act-branding .toggled #nav-toggle span:before,
.act-branding .toggled #nav-toggle span:after {
  background: var(--nav_color);
}

.act-branding .nav_rock_logo {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 576px) {
  .act-branding nav img {
    max-height: 55px;
  }
}

@media screen and (min-width: 768px) {
  .act-branding nav img {
    max-height: 80px;
  }
  .act-branding .nav_rock_logo img {
    max-height: 60px;
  }
  .act-branding .nav_rock_logo {
    position: relative;
  }
}

/* section/page-specific */
.act-branding .icon-circle-wrap,
.act-branding .instructions-button a,
.act-branding .button_inverted a:hover {
  background-image: var(--main_accent_act-gradient);
}

.act-branding .test_now span:nth-child(2),
.act-branding .test_three span:nth-child(2) {
  color: var(--main_accent_act);
}
.act-branding .icon-circle {
  background: var(--main_accent_act-gradient);
}
.act-branding .footer-logo img {
  max-width: 220px;
}

input[type="text"].zip-search:active,
input[type="text"].zip-search:focus,
input[type="text"].zip-search:focus-within,
input[type="text"].zip-search:focus-visible {
  box-shadow: none;
  border: 1px solid var(--main_accent_act);
}
.act-branding .accessibility {
  display: none;
}

.act-branding .language-switcher-act button:first-of-type span {
  border-right-color: #fff;
}
@media screen and (min-width: 768px) {
  /* Align footer language switcher correctly on act */
  .act-branding .language-switcher-act button:first-of-type span {
    padding-left: 0;
  }
}
