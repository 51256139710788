/* About the tests */
.about-the-tests-hero {
  text-align: center;
}
.about-the-tests-hero .col-2-sm {
  align-items: center;
}
.about-the-tests-hero > div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: Center;
}
.about-the-tests-hero img {
  max-height: 200px;
}
.when-to-test img {
  height: 100px;
}
.test_twice,
.col-2-sm > div:first-of-type.test_now,
.col-2-sm > div:nth-of-type(2).test_three {
  padding: 2rem;
}
.test_twice {
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
}

.test_now,
.test_three {
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid var(--border);
}
.test_now p,
.test_three p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.test_three p {
  flex-wrap: wrap;
}
.test_now span:nth-child(1),
.test_three span:nth-child(1) {
  font-size: 7vw;
  line-height: 4vw;
}
.test_now span:nth-child(2) {
  font-size: 15vw;
  line-height: 14vw;
  font-weight: 800;
  color: var(--main_accent);
  padding: 0.5rem;
  text-transform: none;
}

/* Additional padding for descenders on Khmer/Cambodian */
.language-km .test_now span:nth-child(2) {
  padding-bottom: 0.5em;
}

.test_three span:nth-child(3) {
  font-size: 4vw;
  line-height: 4vw;
  max-width: 100%;
}
.test_three span:nth-child(2) {
  padding: 0.5rem;
  font-size: 8vw;
  line-height: 7vw;
  font-weight: 800;
  color: var(--main_accent);
}

@media screen and (min-width: 576px) {
  .test_now {
    border-right: 1px solid var(--border);
  }
  html[dir="rtl"] .test_now {
    border-right: 0;
    border-left: 1px solid var(--border);
  }
  .test_now,
  .test_three {
    border-bottom: 1px solid var(--border);
  }
  .test_now span:nth-child(1),
  .test_three span:nth-child(1) {
    font-size: 4vw;
    line-height: 4vw;
  }
  .test_now span:nth-child(2) {
    font-size: 9vw;
    line-height: 9vw;
  }
  .test_three span:nth-child(3) {
    font-size: 2.5vw;
    line-height: 2.5vw;
  }
  .test_three span:nth-child(2) {
    font-size: 6vw;
    line-height: 6vw;
  }
}
@media screen and (min-width: 1200px) {
  .test_now span:nth-child(1) {
    font-size: 55px;
    line-height: 55px;
  }
  .test_now span:nth-child(2) {
    font-size: 115px;
    line-height: 100px;
  }
  .test_three span:nth-child(1),
  .test_three span:nth-child(3) {
    font-size: 36px;
    line-height: 36px;
  }
  .test_three span:nth-child(2) {
    font-size: 85px;
    line-height: 85px;
  }
}

/* .instructions img{max-width:260px;padding:1rem;} */
.instructions > div:first-child {
  padding-right: 1rem;
}
.instructions > div:nth-child(2) {
  padding-left: 1rem;
}
.instructions-button {
  display: block;
  margin: 2rem auto;
  max-width: 300px;
}
.instructions-button P {
  margin-bottom: 0;
  margin-top: 0;
  max-width: 80%;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  line-height: 130%;
  font-size: 15px;
}
.instructions-button a {
  padding: 1rem;
  text-decoration: none;
  box-shadow: var(--box-shadow);
  transition: all 0.5s ease-in-out;
}
.instructions-button a:hover {
  box-shadow: none;
}
.instructions-button .fas,
.instructions-button img {
  padding: 1rem;
  font-size: 52px;
  height: 75px;
  display: flex;
  align-items: center;
}
.instructions-button a {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--border_radius);
  background: var(--main_accent);
}
.instructions-button.dig-assist img {
  padding: 0.5rem 0 1rem 0;
}

.icons-wrap {
  display: flex;
  justify-content: center;
}
.icon-card {
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
  border: 1px solid var(--border);
  border-radius: 10px;
  padding: 2.5rem 2rem 2rem 2rem;
  height: 100%;
  margin: 1rem;
}
.icon-card h3 {
  font-weight: bold;
  margin-bottom: 0;
}
.icon-card p {
  margin-top: 0;
}
.icon-circle-wrap {
  background: var(--main_accent);
  border-radius: 500px;
  padding: 1.25rem;
  height: 90px;
  width: 90px;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.icon-circle img {
  height: 100%;
  display: flex;
}

.positive-negative {
  max-width: 800px;
}
.positive-negative h4 {
  margin-top: 2rem;
}
.toggles {
  padding-bottom: 2rem;
  display: flex;
  max-width: 700px;
  margin: 2rem auto;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
}
#positive-toggle,
#negative-toggle {
  color: white;
  transition: all 0.5s ease-in-out;
  border-radius: var(--border_radius);
  margin: 0.5rem auto;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 1rem;
  box-shadow: var(--box-shadow);
  width: 100%;
  justify-content: center;
  hyphens: auto;
  word-break: normal;
  min-height: 100%;
}

#positive-toggle:hover,
#negative-toggle:hover {
  box-shadow: none;
}
#positive-toggle i,
#negative-toggle i {
  padding-right: 1rem;
  font-size: 2rem;
}
#positive-toggle {
  background: var(--main_accent);
}
#negative-toggle {
  background: #5299d3;
}
#positive-toggle.inactive,
#negative-toggle.inactive {
  opacity: 0.6;
}
#positive,
#negative {
  display: none;
}
#positive.toggled,
#negative.toggled {
  display: block;
}

@media screen and (min-width: 576px) {
  .toggles {
    min-width: 480px;
    flex-direction: row;
  }

  #positive-toggle,
  #negative-toggle,
  #positive-toggle i,
  #negative-toggle i {
    font-size: 3rem;
  }
  #positive-toggle,
  #negative-toggle {
    margin: 0.5rem;
    max-width: 50%;
    min-width: 200px;
  }
  .why-say-yes .section-text {
    padding: 2rem 2rem 3rem 2rem;
  }
  /* .test_now{border-bottom:none;border-right:1px solid var(--border);} */
}
@media screen and (min-width: 768px) {
  .about-the-tests-hero {
    text-align: left;
    align-items: center;
  }
  .about-the-tests-hero img {
    max-height: 430px;
  }
  .why-say-yes .section-text {
    padding: 3rem 3rem 4rem 3rem;
  }
}
@media screen and (min-width: 1024px) {
  .why-say-yes li {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  .why-say-yes .section-text {
    padding: 5rem 5rem 6rem 5rem;
  }
}
@media screen and (min-width: 1024px) {
  .about-the-tests-hero {
    padding-left: 0;
    padding-right: 0;
  }
}
