/* LAYOUT */
body {
  display: flex;
}
#root {
  width: 100%;
}

img {
  height: auto;
  width: auto;
  max-width: 100%;
}

.center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}
section {
  padding: 3rem;
  margin: 0 auto;
  max-width: 1050px;
}
.section-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-direction: column;
}
[data-aos="zoom-y-out"] {
  transform: scaleX(1.03);
  opacity: 0;
  transition-property: transform, opacity;
}

section.collage .section-image {
  display: none;
}

@media screen and (min-width: 576px) {
  section {
    padding: 4rem;
  }
  section.collage .section-image {
    display: block;
  }
  section.collage {
    padding: 0;
  }
  section.collage {
    margin: 0 4rem;
  }
}
@media screen and (min-width: 768px) {
  section {
    padding: 5rem;
  }
  section.collage {
    padding: 0;
  }
  section.collage {
    margin: 0 5rem;
  }
}
@media screen and (min-width: 1200px) {
  /* section{padding-left:0;padding-right:0;} */
  section {
    max-width: 1110px;
  }
  section.collage {
    margin: 0 auto;
  }
}
@media screen and (min-width: 1400px) {
}
.col-3-xs img {
  max-width: 150px;
}

.dark-bg {
  background: var(--main_accent);
}
.highlighted {
  background: var(--highlighted);
}
.dark-bg p,
.dark-bg a,
.dark-bg h1,
.dark-bg h2,
.dark-bg h3,
.dark-bg h4,
.dark-bg h5,
.dark-bg h6,
.dark-bg .lead p,
.dark-bg .lead ul,
.dark-bg .newstitle {
  color: white;
}

/* MEDIA QUERIES */
@media screen and (min-width: 420px) {
  .col-3-xs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .col-3-xs > div {
    width: 33.3333%;
  }
}
@media screen and (min-width: 576px) {
  .col-2-sm {
    display: flex;
    flex-direction: row;
  }
  .col-2-sm > div {
    width: 50%;
  }
  .col-2-sm > div:first-of-type {
    padding-right: 1rem;
  }
  .col-2-sm > div:nth-of-type(2) {
    padding-left: 1rem;
  }
  .thirty-70.col-2-sm > div:first-of-type {
    padding-right: 1rem;
    width: 30%;
  }
  .thirty-70.col-2-sm > div:nth-of-type(2) {
    width: 70%;
    display: flex;
    justify-content: center;
    padding-left: 1rem;
  }
  .forty-60.col-2-sm > div:first-of-type {
    width: 40%;
    padding-right: 1rem;
  }
  .forty-60.col-2-sm > div:nth-of-type(2) {
    padding-left: 1rem;
    width: 60%;
    display: flex;
    justify-content: center;
  }
  .sixty-40.col-2-sm > div:first-of-type {
    width: 60%;
    padding-right: 1rem;
  }
  .sixty-40.col-2-sm > div:nth-of-type(2) {
    padding-left: 1rem;
    width: 40%;
  }
  .seventy-30.col-2-sm > div:first-of-type {
    padding-right: 1rem;
    width: 70%;
  }
  .seventy-30.col-2-sm > div:nth-of-type(2) {
    width: 30%;
    padding-left: 1rem;
  }

  .col-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  .test_now {
    border-bottom: none;
    border-right: 1px solid var(--border);
  }

  /* .vaccinated{flex-direction:row-reverse} */
}
@media screen and (min-width: 768px) {
  .col-2,
  .col-2-md {
    display: flex;
    justify-content: space-between;
  }
  .col-2 > div,
  .col-2-md > div {
    width: 50%;
  }
  .col-2-md-reverse {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .col-2-md > div:first-of-type {
    padding-right: 1rem;
  }
  .col-2-md > div:nth-of-type(2) {
    padding-left: 1rem;
  }
  .forty-60.col-2-md > div:first-of-type {
    width: 40%;
    padding-right: 1rem;
  }
  .forty-60.col-2-md > div:nth-of-type(2) {
    width: 60%;
    padding-left: 1rem;
  }
  .sixty-40.col-2-md > div:first-of-type {
    width: 60%;
    padding-right: 1rem;
  }
  .sixty-40.col-2-md-reverse > div:nth-of-type(2) {
    width: 40%;
    padding-right: 1rem;
  }
  .sixty-40.col-2-md-reverse > div:first-of-type {
    width: 60%;
    padding-left: 1rem;
  }
  .sixty-40.col-2-md > div:nth-of-type(2) {
    width: 40%;
    padding-left: 1rem;
  }
  .seventy-30.col-2-md > div:first-of-type {
    width: 70%;
    padding-right: 1rem;
  }
  .seventy-30.col-2-md > div:nth-of-type(2) {
    width: 30%;
    padding-left: 1rem;
  }
}
