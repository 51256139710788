/* .home-page #search-module{padding-top:1rem;} */
.feature.two img {
  width: 100%;
}
.vaccinated {
  flex-direction: column-reverse;
}
.vaccinated img {
  max-width: 100%;
  width: 200px;
}
@media screen and (min-width: 576px) {
  .vaccinated {
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .vaccinated .section-text,
  .want-to-help .section-text {
    padding-right: 6rem;
  }
}
