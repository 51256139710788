.ihealth-expiration {
  background-color: #ff5200;
}

.ihealth-expiration h3.ihealth-header {
  text-align: center;
  max-width: 1050px;
  margin: 0 auto;
  color: #fff;
  padding: 5px 5px 0 5px;
  font-size: 1em;
  font-weight: bold;
}

.ihealth-expiration p {
  text-align: center;
  max-width: 1050px;
  margin: 0 auto;
  color: #fff;
  padding: 5px;
  font-size: 0.7em;
}

.ihealth-expiration a {
  color: #ffffff;
  font-size: 1em;
}
