footer {
  background-color: var(--footer_background);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
footer a,
footer a:hover,
footer a:focus,
footer a:active,
footer a:-webkit-any-link {
  color: white;
  text-decoration: none;
}
footer .copyright {
  color: white;
  display: flex;
}
footer .accessibility {
  font-size: 90%;
  color: white;
  text-decoration: none;
}
footer .accessibility a {
  font-size: inherit;
  color: white;
  text-decoration: underline;
}
footer .disclaimer a {
  font-size: 75%;
  color: white;
  text-decoration: none;
}
footer .disclaimer a,
footer .copyright {
  padding: 1rem 0;
}
footer .language-switcher i {
  color: white;
}

footer .language-switcher select {
  color: white;
}
footer .language-switcher option {
  color: black;
}
.footer-logo img {
  max-width: 250px;
  width: 100%;
}
.footer_left {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  font-size: 1.5rem;
}
.footer_right {
  display: flex;
  flex-direction: column;
}
.funded {
  color: white;
  font-size: 1.5rem;
  padding-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .footer_right {
    flex-direction: row;
    max-width: 540px;
  }
  .funded {
    text-align: right;
    padding-bottom: 0;
    padding-right: 2rem;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    width: 50%;
    align-items: center;
    display: flex;
  }
  .footer-logo {
    padding-left: 2rem;
    max-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  footer {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .footer_left {
    align-items: flex-start;
    padding-bottom: 2rem;
  }
  footer .copyright,
  footer .accessibility,
  footer .disclaimer {
    text-align: left;
  }
}
