/* nunito-200 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  src: url("./nunito/nunito-v20-latin-200.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./nunito/nunito-v20-latin-200.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./nunito/nunito-v20-latin-200.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./nunito/nunito-v20-latin-200.woff")
      format("woff"),
    /* Modern Browsers */ url("./nunito/nunito-v20-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./nunito/nunito-v20-latin-200.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url("./nunito/nunito-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./nunito/nunito-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./nunito/nunito-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./nunito/nunito-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./nunito/nunito-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./nunito/nunito-v20-latin-regular.svg#Nunito") format("svg"); /* Legacy iOS */
}
/* nunito-300 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  src: url("./nunito/nunito-v20-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./nunito/nunito-v20-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./nunito/nunito-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./nunito/nunito-v20-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./nunito/nunito-v20-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./nunito/nunito-v20-latin-300.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* nunito-500 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  src: url("./nunito/nunito-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./nunito/nunito-v20-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./nunito/nunito-v20-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./nunito/nunito-v20-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("./nunito/nunito-v20-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./nunito/nunito-v20-latin-500.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* nunito-600 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: url("./nunito/nunito-v20-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./nunito/nunito-v20-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./nunito/nunito-v20-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./nunito/nunito-v20-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("./nunito/nunito-v20-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./nunito/nunito-v20-latin-600.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* nunito-800 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: url("./nunito/nunito-v20-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./nunito/nunito-v20-latin-800.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./nunito/nunito-v20-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./nunito/nunito-v20-latin-800.woff")
      format("woff"),
    /* Modern Browsers */ url("./nunito/nunito-v20-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./nunito/nunito-v20-latin-800.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* nunito-700 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: url("./nunito/nunito-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./nunito/nunito-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./nunito/nunito-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./nunito/nunito-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./nunito/nunito-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./nunito/nunito-v20-latin-700.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* nunito-900 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  src: url("./nunito/nunito-v20-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./nunito/nunito-v20-latin-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./nunito/nunito-v20-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./nunito/nunito-v20-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("./nunito/nunito-v20-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./nunito/nunito-v20-latin-900.svg#Nunito")
      format("svg"); /* Legacy iOS */
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
.newstitle {
  font-family: "Nunito", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  clear: right;
  font-weight: 700;
}

html {
  color: var(--text_color);
}
b,
strong {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
.lead p,
.lead ul,
.newstitle {
  color: var(--main_accent);
  font-weight: 500;
}
a {
  color: royalblue;
  transition: (--transition);
}
a:visited {
  color: purple;
}
a:hover,
a:focus,
a:active {
  color: var(--activelink);
}

p,
ul,
ol,
li,
button,
a,
.fas,
div {
  font-size: 1.7rem;
  line-height: 150%;
  word-break: break-word;
}
h1 {
  font-size: 4rem;
  line-height: 120%;
  margin-bottom: 1.5rem;
}
h2 {
  font-size: 3rem;
  line-height: 120%;
  margin-bottom: 1.5rem;
}
h3 {
  font-size: 2rem;
  line-height: 120%;
  margin-bottom: 1.5rem;
}
p,
li {
  margin: 0.75rem 0;
}
/* p:last-child,li:last-child{margin-bottom:0;} */
a {
  cursor: pointer;
}

small {
  font-size: 75%;
}

@media screen and (min-width: 768px) {
  p,
  ul,
  ol,
  li,
  button,
  a,
  .fas,
  div {
    font-size: 2rem;
  }
  section {
    padding: 5rem;
  }
}
@media screen and (min-width: 992px) {
  h1 {
    font-size: 5rem;
  }
  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}

ul,
ol {
  margin: 0 0 0 1em;
}
