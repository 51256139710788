input:focus,
input:focus-within,
input:focus-visible,
input:active,
input:hover{
 outline:0;
}
input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], textarea {
  padding: 1rem 2rem;
  color: var(--nav_color);
  border: 1px solid var(--border);
  font-family: 'nunito', sans-serif;
  border-radius: 100px;
}
.button a{
  box-shadow:var(--box-shadow);
}
.button a:link,
.button a:visited {
  font-weight: 700;
  background-color: var(--main_accent);
  color: white;
  padding: 16px 38px;
  border-radius: 100px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.5s ease;
  border: 2px solid var(--main_accent);
}
.button a:hover {
  background: white;
  color: var(--main_accent);
  transition: all 0.5s ease;
  box-shadow:none;
}

.button_inverted a:link,
.button_inverted a:visited {
  background-color: white;
  color: var(--main_accent);
  border:2px solid var(--main_accent);
}
.button_inverted a:hover{
  color: white;
  background-color: var(--main_accent);
}
