@import url("base/reset.css");
/* @import url("base/old-base-styles.css"); */
/* @import url("base/old-template-theme.css"); */

:root {
  --main_accent: #b7294d; /* #dc1351; */
  --text_color: #4a5568;
  --footer_background: #333333;
  --nav_color: #5d6e81;
  --activelink: #00518d;
  --highlighted: #fffced;
  --border: #ddd;
  --border_radius: 12px;
  --box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  font-family: "nunito", sans-serif;

  --main_accent_act: #107e77;
  --main_accent_act-gradient: linear-gradient(to right, #107e77, #076660);
  --dark_accent-act: #1e3838;
  --transition: all 0.5s ease;
}

@import url("base/typ.css");
@import url("base/forms.css");
@import url("base/layout.css");

@import url("partials/language-switcher.css");
@import url("partials/nav.css");
@import url("partials/footer.css");
@import url("partials/accordion.css");
@import url("partials/search-module.css");
@import url("partials/ihealth-expiration.css");

@import url("pages/home.css");
@import url("pages/about.css");
@import url("pages/faq.css");
@import url("pages/faq-disclaimer.css");

@import url("syctn-branding.css");
@import url("act-branding.css");
@import url("default-branding.css");
