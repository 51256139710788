/* LANGUAGE SWITCHER */
.main-navigation.toggled .language-switcher .nav-menu {
  display: flex;
  justify-content: flex-start;
}

.language-switcher {
  display: flex;
  align-items: center;
  position: relative;
}

.language-switcher.stacked {
  flex-direction: column;
}

.language-switcher .fas {
  line-height: 100%;
  font-size: 2.5rem;
}

.language-switcher.stacked .fas {
  font-size: 3rem;
  line-height: 2rem;
}

.language-switcher > * {
  margin: 0 2px;
}

.select-container {
  position: relative;
}

.language-switcher.stacked .select-container {
  position: unset;
}

.language-switcher select {
  padding: 0.5rem;
  font-size: 1.5rem;
  padding-right: 2rem;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--nav_color);
  transition: all 0.3s;
  -webkit-appearance: none;
  display: block;
  line-height: 1.75rem;
}

.language-switcher.stacked select {
  border: 0;
  padding-right: 0.5rem;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.language-switcher.stacked select:hover {
  opacity: 0;
}

.language-switcher.stacked .current-value {
  text-align: center;
  padding: 0.5rem;
  color: var(--nav_color);
  line-height: 1.75rem;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.3rem;
  pointer-events: none;
}

.language-switcher select:hover {
  opacity: 0.7;
}

.language-switcher .select-arrow {
  position: absolute;
  font-size: 1.5rem;
  right: 1rem;
  pointer-events: none;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 1;
}

.language-switcher.stacked .select-arrow {
  display: none;
}

html[dir="rtl"] .language-switcher:not(.stacked) {
  flex-direction: row-reverse;
}

html[dir="rtl"] .language-switcher .select-arrow {
  right: auto;
  left: 1rem;
}
html[dir="rtl"] .language-switcher select {
  padding-right: 0.5rem;
  padding-left: 2rem;
}

/* ACT LANGUAGE SWITCHER */
.language-switcher-act {
  display: flex;
}
.language-switcher-act button {
  padding: 1rem 0;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: 0;
  color: #fff;
  transition: all 0.3s;
}
.language-switcher-act button:hover {
  opacity: 0.7;
}

.language-switcher-act button span {
  padding: 0 1rem;
}
.language-switcher-act button:first-of-type span {
  border-right: 1px solid rgba(68, 128, 134, 0.7);
}
.dark-bg .language-switcher-act button:first-of-type span {
  border-right: 1px solid var(--border_color);
}
