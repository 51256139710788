.disclaimer,
.accordion {
  max-width: 900px;
}
.disclaimer {
  margin: 0 auto;
}
.disclaimer h1 {
  text-align: center;
}
.accordion section:not(.intro) {
  padding-top: 0;
}
.accordion h2 {
  background: var(--highlighted);
}
.toc span {
  cursor: pointer;
}
