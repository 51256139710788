.default-branding #site-header,
.syctn-branding #site-header {
  width: 50%;
}

.syctn-branding .site-logo {
  width: 350px;
  max-width: 100%;
}
.syctn-branding .footer-logo img {
  width: 250px;
  max-width: 100%;
}
.syctn-branding nav a {
  transition: var(--transition);
  color: var(--nav_color);
}
.syctn-branding nav a:hover {
  color: var(--main_accent);
}
.syctn-branding .disclaimer {
  display: none;
}

.syctn-branding .faq-about .accordion .panel:nth-of-type(4),
.syctn-branding .faq-testing .accordion .panel:nth-of-type(1),
.syctn-branding .faq-kits .accordion .panel:nth-of-type(5) {
  display: none;
}

@media screen and (min-width: 768px) {
  .syctn-branding .site-logo {
    position: relative;
    top: 1rem;
  }
}
