.default-branding .site-logo {
  width: 350px;
  max-width: 100%;
}
.default-branding .footer-logo img {
  width: 250px;
  max-width: 100%;
}
.default-branding nav a {
  transition: var(--transition);
  color: var(--nav_color);
}
.default-branding nav a:hover {
  color: var(--main_accent);
}
.default-branding .disclaimer {
  display: none;
}

.default-branding .faq-about .accordion .panel:nth-of-type(4),
.default-branding .faq-testing .accordion .panel:nth-of-type(1),
.default-branding .faq-kits .accordion .panel:nth-of-type(5) {
  display: none;
}

@media screen and (min-width: 768px) {
  .default-branding .site-logo {
    position: relative;
    top: 1rem;
  }
}
