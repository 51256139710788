/* SEARCH MODULE */
.eligible h1 {
  margin-bottom: 0;
}
.pick-one {
  max-width: 450px;
  padding: 0 2rem;
}
.pick-one:first-child {
  padding-bottom: 3rem;
}
input[type="text"].zip-search {
  font-size: 2rem;
  text-align: center;
  padding: 1rem;
  padding-right: 50px;
  width: 100%;
  box-shadow: var(--box-shadow);
}

/* Solving issue with long text in zip search in somali, ukranian, and russian */
.language-ru input[type="text"].zip-search,
.language-so input[type="text"].zip-search,
.language-uk input[type="text"].zip-search {
  font-size: 2rem;
  padding: 1.2rem;
  margin-left: 0;
  min-width: 300px;
}
.language-ru .search-button,
.language-so .search-button,
.language-uk .search-button {
  right: auto;
}

@media screen and (max-width: 400px) {
  .language-ru input[type="text"].zip-search,
  .language-so input[type="text"].zip-search,
  .language-uk input[type="text"].zip-search {
    font-size: 1.5rem;
    min-width: 220px;
  }
}

html[dir="rtl"] input[type="text"].zip-search {
  padding-left: 50px;
  padding-right: 0.5rem;
}
input[type="text"].zip-search:hover {
  cursor: pointer;
}

input[type="text"].zip-search:active,
input[type="text"].zip-search:focus,
input[type="text"].zip-search:focus-within,
input[type="text"].zip-search:focus-visible {
  box-shadow: none;
  border: 1px solid var(--main_accent);
}

#search-module h1 {
  margin-bottom: 0;
}
#search-module h1 strong {
  font-weight: normal;
}
#search-module h1 strong:first-of-type {
  font-weight: bold;
}
.search-field {
  margin: auto;
  display: flex;
  column-count: 1;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}
.search-button {
  width: 44px;
  height: 44px;
  cursor: pointer;
}
.order-button {
  margin-top: 1rem;
}
/* MODAL */
.modal-overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-window {
  position: absolute;
  background: white;
  padding: 2rem;
  text-align: center;
  max-width: 730px;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 12px;
}
.modal-close {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  top: -1.75rem;
  right: -1.75rem;
}

html[dir="rtl"] .modal-close {
  right: auto;
  left: -1.75rem;
}

.dept-logo {
  max-width: 260px;
  padding: 1rem;
  max-height: 130px;
  padding-top: 0;
  margin-top: -1.5rem;
  margin-bottom: 0.5rem;
}
.map {
  padding-top: 2rem;
  max-width: 400px;
}
.map img {
  max-height: 300px;
}

.hide {
  display: none !important;
}

/* .wait-message{
    max-width: 600px;
}
.wait-message h3 {
    margin-bottom:1rem;
} */

@media screen and (min-width: 576px) {
  .eligible .modal-header {
    height: 0;
  }
  .modal-window {
    padding: 2rem 4rem;
    border-radius: 2rem;
  }
  .modal-close {
    right: -3.75rem;
  }

  html[dir="rtl"] .modal-close {
    left: -3.75rem;
  }

  .pick-one:first-child {
    border-right: 2px dashed gray;
    padding-bottom: 0;
  }

  input[type="text"].zip-search {
    margin-left: 44px;
    max-width: calc(100% - 44px);
  }
  html[dir="rtl"] input[type="text"].zip-search {
    margin-right: 44px;
    margin-left: auto;
  }
  .search-button {
    right: 50px;
    position: relative;
  }

  html[dir="rtl"] .search-button {
    right: auto;
    left: 50px;
    position: relative;
  }
  /* .wait-message h3 {
        margin-bottom:0;
    }
    .wait-message p{
        margin-bottom:0;padding-bottom:0;
    } */
}
@media screen and (min-width: 768px) {
  .map {
    padding-top: 0;
    max-width: 100%;
  }
  .map img {
    max-height: 430px;
  }
  input[type="text"].zip-search {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 870px) {
  input[type="text"].zip-search {
    font-size: 3rem;
  }
}
