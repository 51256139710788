/* old */

/* .main-navigation {
  margin-top: 5px;
  margin-right: 20px;
  margin-bottom: 0px;
} */

/* set the current background menu color:*/
.main-navigation .current_page_item > a,
.main-navigation .current-menu-item > a,
.main-navigation .current_page_item > a:hover,
.main-navigation .current-menu-item > a:hover,
.main-navigation li > a:hover {
  /* background-color: #fdede3 !important;
  color: black; */
  border-bottom: 4px solid var(--main_accent);
}

.main-navigation ul {
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  .main-navigation .language .wpml-ls-current-language a:link {
    padding-left: 40px;
  }

  .toggled .language {
    float: none;
    text-align: center;
  }
}
button#nav-toggle {
  z-index: 2;
  height: 32px;
  border: none;
  box-shadow: none;
  text-shadow: none;
  background: transparent;
  font-size: 3rem;
  cursor: pointer;
  padding: 0px 2px 4px;
  margin: 10px;
  width: 39px;
}

#nav-toggle span:before {
  top: -10px;
}

#nav-toggle span:after {
  bottom: -10px;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 4px;
  width: 35px;
  background: #00518d;
  position: absolute;
  display: block;
  content: "";
  transition: all 200ms ease-in-out;
}
.toggled #nav-toggle span:before,
.toggled #nav-toggle span:after {
  background: var(--nav_color);
}

.toggled #nav-toggle span {
  background-color: transparent !important;
}

.toggled #nav-toggle span:before,
.toggled #nav-toggle span:after {
  top: 0;
}

.toggled #nav-toggle span:before {
  transform: rotate(45deg);
}

.toggled #nav-toggle span:after {
  transform: rotate(-45deg);
}

#nav-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
}

html[dir="rtl"] #nav-toggle {
  right: auto;
  left: 0;
}

/* new */
nav {
  padding: 1rem;
}
nav > div {
  padding: 0 0.5rem;
}
nav,
.header {
  display: flex;
}

nav .current_page_item > a:link,
nav .current_page_item > a:visited,
nav li:hover > a {
  color: var(--activelink);
}

nav li {
  margin: 0;
}

nav a {
  text-decoration: none;
  line-height: 1.3em;
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border-bottom: 4px solid transparent;
}
.menu li a {
  padding: 0;
  height: 44px;
  margin-top: 0;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-right: 1rem;
}

.nav_rock_logo {
  display: none;
}
#nav-toggle {
  display: block;
  height: 32px;
  padding: 0 12px;
  font-weight: normal;
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  line-height: 18px;
}
.main-navigation {
  justify-content: space-between;
}
.main-navigation.toggled {
  padding: 0;
}
.main-navigation ul {
  display: none;
  padding-left: 0;
  list-style-type: none;
}

.main-navigation.toggled .nav-menu {
  display: block;
  border-top: 1px solid;
}
.main-navigation.toggled ul {
  padding-top: 6px;
  border-top: 0px solid transparent;
  display: block;
  list-style: none;
  margin: 0;
}
.main-navigation.toggled ul {
  margin-left: 1rem;
}
#site-header {
  max-width: 300px;
  z-index: 2;
  display: flex;
}
.toggled #site-header {
  z-index: -1;
}
.lang-n-pages {
  padding: 0;
  width: 60px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.lang-n-pages .language-switcher {
  justify-content: flex-end;
}

.toggled .lang-n-pages {
  z-index: 2;
  box-shadow: var(--box-shadow);
  background: white;
  position: fixed;
  width: 100%;
  padding: 1rem 2rem 1.5rem 2rem;
}

.main-navigation {
  width: 100%;
  display: flex;
  clear: both;
  text-transform: uppercase;
  position: relative;
}

.main-navigation > .language-switcher {
  display: none;
}

.main-navigation .language-switcher-act {
  display: none;
  list-style-type: none;
  padding-left: 0;
}

.main-navigation.toggled .language-switcher-act {
  display: flex;
  justify-content: flex-start;
}
@media screen and (max-width: 767px) {
  /* Hide the instance of the language-switcher inside the menu on mobile */
  .lang-n-pages .language-switcher {
    display: none;
  }

  /* And show the instance outside the menu on mobile */
  .main-navigation > .language-switcher {
    display: flex;
    margin-left: auto;
  }
}

html[dir="rtl"] .main-navigation > .language-switcher {
  margin-right: auto;
  margin-left: 0;
}
@media screen and (min-width: 768px) {
  nav {
    padding: 1rem 2rem;
  }
  #nav-toggle {
    display: none;
  }
  .menu {
    padding-left: 1rem;
  }

  .main-navigation li a {
    margin-top: 0;
  }

  .menu li:first-of-type a {
    margin-right: 2rem;
    padding-left: 0;
    margin-left: 0;
  }
  .lang-n-pages {
    width: auto;
  }

  .main-navigation ul {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  /* Align the ACT language switcher to the right */
  .main-navigation .language-switcher-act {
    display: flex;
    justify-content: flex-end;
  }
}
